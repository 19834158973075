export function handleDiscount(offer: {
  price_before: number;
  price_best_now: number;
}, raw: boolean = false): string | number {
  const priceDiff = offer.price_before - offer.price_best_now;
  const discount = (priceDiff / offer.price_before) * 100;

  if (raw) {
    return (priceDiff < discount) ? Math.round(discount) : Math.round(priceDiff);
  }

  return (priceDiff < discount) ? `${Math.round(discount)}%` : `R$ ${Math.round(priceDiff)}`;
}