import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import {until} from 'lit-html/directives/until';
import { getAllActiveOffers } from "@/services/compara-farma/compara-farma";
import { handleDiscount } from "@/helpers/handleDiscount";
import sortByHumanDiscount from "@/helpers/sortByHumanDiscount";

@customElement("wc-cf-offers-page")
export class OffersPage extends LitElement {
  constructor() {
    super();
  }

  @property()
  accessor title: string = "Todas as ofertas";

  @property()
  accessor loading: boolean = true;

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .cards {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
  `;

  render() {
    return html`
      <wc-title><h1 slot="title">${this.title}</h1></wc-title>
      <div class="cards">
        ${until(getAllActiveOffers().then((offers: any) => (
          sortByHumanDiscount(offers.data).map((offer: any) => html`
            <wc-card-simple
              title="${offer.title}"
              ean="${offer.ean}"
              priceBefore="${offer.price_before}"
              price="${offer.price_best_now}"
              discount="${offer.discount}"
              sourceName="${offer.source_name}"
              sourceCount="${offer.source_count}"
              cover="${offer.cover}"
              url="${offer.url}"
            ></wc-card-simple>
          `)
        )), html`<p>Carregando ofertas...</p>`)}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "wc-cf-offers-page": OffersPage;
  }
}
