import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { track } from "@/services/tracking/tracking";
import { handleDiscount } from "@/helpers/handleDiscount";

@customElement("wc-card-simple")
export class CardSimple extends LitElement {
  constructor() {
    super();
  }

  @property()
  accessor title: string = "";

  @property()
  accessor ean: string = "";

  @property()
  accessor cover: string = "";

  @property()
  accessor sourceName: string = "";

  @property()
  accessor sourceCount: number = 1;

  @property()
  accessor discount: number = 0;

  @property()
  accessor price: number = 0;

  @property()
  accessor priceBefore: number = 0;

  @property()
  accessor url: string = "";

  static styles = css`
    :host {
      font-family: "Open Sans", sans-serif;
    }

    .cf__card {
      display: block;
      text-decoration: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      max-width: 250px;
    }

    .cf__cover {
      position: relative;
      overflow: hidden;
    }

    .cf__cover img {
      width: 100%;
      display: block;
    }

    .cf__discount {
      position: absolute;
      top: 5px;
      left: 5px;
      padding: 5px 10px;
      background-color: #414C73;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .cf__header {
      padding: 5px 10px;
    }

    .cf__title {
      margin: 0;
      font-size: 12px;
      font-weight: normal;
      color: #000;
      line-height: 1.5;
    }

    .cf__subtitle {
      margin: 5px 0 0 0;
      font-size: 12px;
      font-weight: normal;
      color: #616161;
    }

    .cf__prices {
      padding: 5px 10px;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }

    .cf__price {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
    }

    .cf__price--old {
      text-decoration: line-through;
      color: #616161;
      font-size: 12px;
      margin-left: 5px;
    }

    .cf__price--new {
      font-size: 16px;
      color: #000;
    }

    .cf__footer {
      padding: 10px;
    }

    .cf__footer p {
      margin: 0;
      font-size: 12px;
      color: #414C73;
      font-weight: normal;
    }

    .cf__cta {
      display: block;
      width: 100%;
      margin: 0 auto;
      margin-top: 15px;
      text-align: center;
    }
  `;

  render() {
    return html`
      <a class="cf__card" @click=${this.onCardClick()} href="https://agreg.us/r?account=2&url=${this.url}" target="_blank">
        <div class="cf__cover">
          <div class="cf__discount">-${handleDiscount({
            price_before: this.priceBefore,
            price_best_now: this.price
          })}</div>
          <img src="${this.cover}" alt="${this.title}">
        </div>
        <div class="cf__header">
          <h3 class="cf__title">${this.title}</h3>
          <h4 class="cf__subtitle">Menor preço via ${this.sourceName}</h4>
        </div>
        <div class="cf__prices">
          <div class="cf__price cf__price--new">R$ ${String(this.price)?.replace('.', ',')}</div>
          <div class="cf__price cf__price--old">R$ ${String(this.priceBefore)?.replace('.', ',')}</div>
        </div>
        <div class="cf__footer">
          <p>Menor preços dos últimos 7 dias comparando em ${this.sourceCount} lojas</p>
        </div>
      </a>
    `;
  }

  private onCardClick() {
    track("product-clicked", {
      title: this.title,
      source: this.sourceName,
      price: this.price,
      product: "compara-farma"
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "wc-card-simple": CardSimple;
  }
}
