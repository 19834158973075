import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("wc-title")
export class Title extends LitElement {
  constructor() {
    super();
  }

  static styles = css`
    :host {
      font-family: "Open Sans", sans-serif;
      padding-bottom: 9px;
      border-bottom: 1px solid #eee;
    }

    ::slotted(h1) {
      font-size: 18px;
      font-weight: 500;
      margin: 20px 0 10px;
    }
  `;

  render() {
    return html`
      <slot name="title"></slot>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "wc-title": Title;
  }
}