import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import {until} from 'lit-html/directives/until';
import { getAllActiveOffers } from "@/services/compara-farma/compara-farma";
import sortByHumanDiscount from "@/helpers/sortByHumanDiscount";

@customElement("wc-cf-side-widget")
export class SideWidget extends LitElement {
  constructor() {
    super();
  }

  @property()
  accessor title: string = "Compara Farma";

  @property()
  accessor items: number = 4;

  @property()
  accessor loading: boolean = true;

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  `;

  render() {
    return html`
      <wc-title><h1 slot="title">${this.title}</h1></wc-title>
      ${until(getAllActiveOffers().then((offers: any) => (
        sortByHumanDiscount(offers.data).slice(0, this.items).map((offer: any) => html`
          <wc-card-simple
            title="${offer.title}"
            ean="${offer.ean}"
            priceBefore="${offer.price_before}"
            price="${offer.price_best_now}"
            discount="${offer.discount}"
            sourceName="${offer.source_name}"
            sourceCount="${offer.source_count}"
            cover="${offer.cover}"
            url="${offer.url}"
          ></wc-card-simple>
        `)
      )), html`<p>Carregando ofertas...</p>`)}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "wc-cf-side-widget": SideWidget;
  }
}
