import { handleDiscount } from "./handleDiscount";

export default function sortByHumanDiscount(offers: any) {
  return offers.sort(
    (a: any, b: any) => handleDiscount({
      price_before: b.price_before,
      price_best_now: b.price_best_now
    }, true) as number - (handleDiscount({
      price_before: a.price_before,
      price_best_now: a.price_best_now
    }, true) as number)
  )
}