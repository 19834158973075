import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { track } from "@/services/tracking/tracking";

@customElement("wc-intent-exit")
export class IntentExit extends LitElement {
  get dialog(): HTMLDialogElement {
    return this.shadowRoot?.querySelector("#chie") as HTMLDialogElement;
  }

  get closeButton(): HTMLAnchorElement {
    return this.shadowRoot?.querySelector(".chie__close") as HTMLAnchorElement;
  }

  @property()
  accessor title: string = "";

  @property()
  accessor description: string = "[]";

  @property()
  accessor ctaLabel: string = "";

  @property()
  accessor ctaUrl: string = "";

  @property()
  accessor imageSrc: string = "";

  @property()
  accessor imageAlt: string = "";

  constructor() {
    super();

    document.querySelector("html")?.addEventListener("mouseleave", (event) => {
      const mouseY = event.clientY;
      const isDesktop = window.innerWidth > 768;
      const shouldShowModal =
        window.localStorage.getItem("@app:chie") !== "true";

      if (mouseY < 0 && shouldShowModal && isDesktop) {
        window.localStorage.setItem("@app:chie", "true");
        this.dialog.showModal();
      }
    });
  }

  static styles = css`
    .chie {
      position: fixed;
      width: 700px;
      height: 500px;
      top: 50%;
      left: 50%;
      margin-left: -350px;
      margin-top: -250px;
      background-color: #fff;
      z-index: 9999;
      border: none;
      padding: 0;
    }

    .chie__container {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .chie__left {
      width: 50%;
      height: calc(100% - 40px);
      padding: 20px;
      background-color: #414c73;
      color: #fff;
    }

    .chie__left h1 {
      color: #fff;
      font-family: "Open Sans";
      font-size: 37px;
      font-style: normal;
      font-weight: 800;
      line-height: 50px;
    }

    .chie__left p {
      color: #fff;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }

    .chie__cta {
      display: block;
      color: #414c73;
      text-align: center;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      padding: 10px 20px;
      background-color: #fff;
      margin-top: 30px;
      text-decoration: none;
    }

    .chie__cta:hover,
    .chie__cta:focus,
    .chie__cta:focus-visible {
      border: 1px solid #fff;
      background-color: #414c73;
      color: #fff;
      text-decoration: none;
    }

    .chie__right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: calc(100% - 40px);
      padding: 20px;
      background-color: #fff;
    }

    .chie__close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .chie::backdrop {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
    }
  `;

  private closeDialog(event: Event) {
    event.preventDefault();
    track("ie_cta_closed", {
      title: this.title,
      ctaLabel: this.ctaLabel,
      ctaUrl: this.ctaUrl,
    });
    this.dialog.close();
  }

  private onCtaClick() {
    track("ie_cta_clicked", {
      title: this.title,
      ctaLabel: this.ctaLabel,
      ctaUrl: this.ctaUrl,
    });
  }

  render() {
    return html`
      <dialog class="chie" id="chie">
        <div class="chie__container">
          <div class="chie__left">
            <header>
              <h1>${this.title}</h1>
            </header>
            <section>
              ${JSON.parse(this.description)?.map(
                (description: string) => html`<p>${description}</p>`
              )}
            </section>
            <footer>
              <a href="${
                this.ctaUrl
              }" target="_blank" class="chie__cta" @click=${this.onCtaClick}>
                ${this.ctaLabel}
              </a>
            </footer>
          </div>
          <div class="chie__right">
            <a href="#" class="chie__close" @click=${this.closeDialog}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M1 1L19 19" stroke="#414C73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19 1L1 19" stroke="#414C73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </a>
            <img src="${this.imageSrc}" alt="${this.imageAlt}">
          </div>
          <div>
      </dialog> 
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "wc-intent-exit": IntentExit;
  }
}
