export function getDataLayer() {
  return dataLayer || [];
}

export function track(event: string, data: any = {}) {
  getDataLayer().push({ event, ...data });
}

declare global {
  var dataLayer: {
    push: (arg0: { event: string }) => void;
  };
}
